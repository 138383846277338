<template>
  <div class="activity" v-if="canView">
    <page-header
      :title="title"
      :icon="activityIcon"
      :links="getLinks()"
      :extra-links="getExtraLinks()"
    >
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row v-if="activity" v-show="!isLoading(loadingName) && !editMode">
      <b-col ref="excelMe" cols="9" class="members-col" v-if="hasPerm('activities.view_coreactivityinscription')">
        <div ref="printMe">
          <div class="hide-here">
            <b-row>
              <b-col cols="6">
                <b>{{ structureName }}</b>
              </b-col>
              <b-col l cols="6" class="text-right">
                {{ currentTime }}
              </b-col>
            </b-row>
          </div>
          <div :class="{ disabled: activity.disabled }" class="activity-title" :style="activity.getHeaderStyle()">
          <b-row>
            <b-col>
              <h2 style="display: inline-block">
                <span class="hide-here">{{ activity.name}} - </span>
                Inscriptions
              </h2>
            </b-col>
            <b-col cols="3" class="no-print">
              <b-form-group
                id="display-mode-group"
                label-for="display-mode"
              >
                <b-form-select
                  id="display-mode"
                  v-model="displayMode"
                >
                  <b-form-select-option :value="null">Ne pas afficher la ville</b-form-select-option>
                  <b-form-select-option value="address">Afficher l'adresse</b-form-select-option>
                  <b-form-select-option value="city">Afficher la ville</b-form-select-option>
                  <b-form-select-option value="comments">Afficher les commentaires</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="text-right no-print">
              <span v-if="activity.disabled" class="badge badge-light">Suspendue</span>&nbsp;
              <disable-activity-button :activity="activity" :youth-home="youthHome" @updated="activity = $event">
              </disable-activity-button>
            </b-col>
          </b-row>
          </div>
          <div class="elements-counter2">
            <counter-label :counter="inscriptions.length" label="inscription"></counter-label>
            <counter-label v-if="isEvent" :counter="countInscriptions(inscriptions)" label="place"></counter-label>
          </div>
          <x-table
            id="inscriptions"
            :columns="columns"
            :items="inscriptions"
            :show-counter="false"
            verbose-name="Inscription"
          ></x-table>
          <div
            style="padding: 5px; background: #222; color: #fff; margin-bottom: 10px;"
            class="no-print"
            v-if="showFilter"
          >
            <b-row>
              <b-col v-if="waitingInscriptions.length">
                <b-checkbox id="showWaiting" v-model="showWaiting">
                  <counter-label
                    :counter="waitingInscriptions.length"
                    label="en attente"
                    label-n="en attente"
                  ></counter-label>
                </b-checkbox>
              </b-col>
              <b-col v-if="cancelledInscriptions.length">
                <b-checkbox id="showCancellation" v-model="showCancellation">
                  <counter-label
                    :counter="cancelledInscriptions.length"
                    label="annulation"
                  ></counter-label>
                </b-checkbox>
              </b-col>
              <b-col v-if="refusedInscriptions.length">
                <b-checkbox id="showRefused" v-model="showRefused">
                  <counter-label
                    :counter="refusedInscriptions.length"
                    label="refusé"
                  ></counter-label>
                </b-checkbox>
              </b-col>
              <b-col v-if="managerItems.length">
                <b-checkbox id="showManagers" v-model="showManagers">
                  <counter-label
                    :counter="managerItems.length"
                    label="animateur"
                  ></counter-label>
                </b-checkbox>
              </b-col>
              <b-col v-if="hasLeftInscriptions.length">
                <b-checkbox id="hasLeft" v-model="showHasLeft">
                  <counter-label
                    :counter="hasLeftInscriptions.length"
                    label="a quitté"
                    label-n="ont quitté"
                  ></counter-label>
                </b-checkbox>
              </b-col>
            </b-row>
          </div>
          <div v-if="showWaiting && waitingInscriptions.length">
            <div class="elements-counter2">
              <counter-label
                :counter="waitingInscriptions.length"
                label="en attente"
                label-n="en attente"
              ></counter-label>
              <counter-label v-if="isEvent" :counter="countInscriptions(waitingInscriptions)" label="place">
              </counter-label>
            </div>
            <x-table
              id="waiting"
              :columns="waitingColumns"
              :items="waitingInscriptions"
              :show-counter="false"
              verbose-name="En attente"
              verbose-name-plural="En attente"
            ></x-table>
          </div>
          <div v-if="showCancellation && cancelledInscriptions.length">
            <div class="elements-counter2">
              <counter-label :counter="cancelledInscriptions.length" label="annulation"></counter-label>
              <counter-label v-if="isEvent" :counter="countInscriptions(cancelledInscriptions)" label="place">
              </counter-label>
            </div>
            <x-table
              id="cancelled"
              :columns="columns"
              :items="cancelledInscriptions"
              :show-counter="false"
              verbose-name="Annulation"
            ></x-table>
          </div>
          <div v-if="showRefused && refusedInscriptions.length">
            <div class="elements-counter2">
              <counter-label :counter="refusedInscriptions.length" label="refus" label-n="refus"></counter-label>
              <counter-label v-if="isEvent" :counter="countInscriptions(refusedInscriptions)" label="place">
              </counter-label>
            </div>
            <x-table
              id="refused"
              :columns="columns"
              :items="refusedInscriptions"
              :show-counter="false"
              verbose-name="Refus"
              verbose-name-plural="Refus"
            ></x-table>
          </div>
          <div v-if="showHasLeft && hasLeftInscriptions.length">
            <div class="elements-counter2">
              <counter-label
                :counter="hasLeftInscriptions.length"
                label="a quitté"
                label-n="ont quitté"
              ></counter-label>
            </div>
            <x-table
              id="waiting"
              :columns="hasLeftColumns"
              :items="hasLeftInscriptions"
              :show-counter="false"
            ></x-table>
          </div>
          <div v-if="showManagers && activity.managedBy.length">
            <div class="elements-counter2">
              <counter-label :counter="activity.managedBy.length" label="animateur"></counter-label>
            </div>
            <x-table
              id="managers"
              :columns="managerColumns"
              :items="managerItems"
              verbose-name="animateur"
            ></x-table>
          </div>
        </div>
      </b-col>

      <b-col cols="3" class="detail-col">
        <activity-info
          :activity="activity"
          :is-youth-home="isYouthHome"
          can-change-managers
        ></activity-info>
      </b-col>
    </b-row>
    <activity-editor
      :activity="activity"
      :youth-home="youthHome"
      v-if="editMode"
      @done="onActivityUpdated($event)"
      @cancel="onEditCancelled($event)"
    ></activity-editor>
    <clone-activity-button
      :activity="activity"
      :modal-id="cloneModalId"
      @create="onActivityCloned($event)"
    ></clone-activity-button>
    <add-activity-inscription-modal
      v-if="activity"
      :activity="activity"
      modal-id="add-activity-inscription-modal"
      @closed="loadInscriptions()"
    ></add-activity-inscription-modal>
    <b-modal
      dialog-class="modal-lg"
      id="bv-modal-generate-individual-links"
      @ok.prevent="onGenerateIndividualLinks"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Générer le PDF"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-file-pdf"></i> Générer les fiches liaisons des inscrits à l'activité</b>
      </template>
      <b-row>
        <b-col>
          <b-form-group
            id="indLinkStartIndex-group"
            label="Indice de début"
            label-for="indLinkStartIndex"
            description="Inscrits classés par ordre d'inscription"
          >
            <b-form-input
              id="indLinkStartIndex"
              type="number"
              v-model="indLinkStartIndex"
              min="1"
              step="1"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="indLinkCount-group"
            label="Nombre par PDF"
            label-for="indLinkCount"
            description="Un nombre trop grand peut causer un temps de génération du fichier très important"
          >
            <b-form-input
              id="indLinkCount"
              type="number"
              v-model="indLinkCount"
              min="1"
              step="1"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <br />
          <a href @click.prevent="onNextIndLink" class="btn-sm btn btn-secondary">
            Suivant
          </a>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="table table-striped small-text table-small">
            <tr v-for="(ins, index) of indLinkActivityInscriptions" :key="ins.id">
              <td>{{ index + (+indLinkStartIndex) }}</td>
              <td>{{ ins.individual.firstAndLastName() }}</td>
              <td>{{ ins.createdOn | dateToString }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import ActivityInfo from '@/components/Activities/ActivityInfo'
import ActivityEditor from '@/components/Activities/ActivityEditor'
import DisableActivityButton from '@/components/Activities/DisableActivityButton'
import CloneActivityButton from '@/components/Activities/CloneActivityModal'
import AddActivityInscriptionModal from '@/components/Activities/AddActivityInscriptionModal'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import router from '@/router'
import { makeActivity, makeActivityInscription, makeActivityListField } from '@/types/activities'
import { distinct } from '@/utils/arrays'
import { compareDates, compareNumbers } from '@/utils/sorting'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import store from '@/store'
import { dateToString } from '@/filters/texts'
import { loadIndividualLinkDocuments } from '@/apis/youth'

export default {
  name: 'activity',
  props: {
    activityId: String,
    youthHome: [String, Number],
  },
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    ActivityInfo,
    AddActivityInscriptionModal,
    CounterLabel,
    CloneActivityButton,
    DisableActivityButton,
    ActivityEditor,
    XTable,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      activity: null,
      loadingName: 'activity',
      activityInscriptions: [],
      editMode: false,
      displayMode: null,
      cloneModalId: 'clone-activity-modal',
      addActivityManagerModal: 'bv-add-activity-manager-modal',
      selectedManager: null,
      indLinkStartIndex: 1,
      indLinkCount: 30,
      fields: [],
      currentTimeFormat: 'llll',
      currentTime: '',
      showCancellation: true,
      showWaiting: true,
      showRefused: true,
      showManagers: true,
      showHasLeft: true,
    }
  },
  async created() {
    this.currentTime = moment().format(this.currentTimeFormat)
    await this.loadData()
    await this.loadInscriptions()
  },
  watch: {
    activityId: async function() {
      await this.loadData()
      await this.loadInscriptions()
    },
    activity: function(newValue, oldValue) {},
    activityInscriptions: function(newValue, oldValue) {},
    title: function() {
      store.dispatch('changeNav', { title: this.title, icon: store.getters.navIcon, })
    },
  },
  computed: {
    structureName() {
      return store.getters.structureName
    },
    title() {
      if (this.isYouthHome) {
        return this.youthHomeLabel
      } else {
        return this.activity ? (this.activity.getCategoryName() + ' ' + this.activity.name) : ''
      }
    },
    category() {
      if (this.activity) {
        return this.activity.category
      } else {
        return 0
      }
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    activityIcon() {
      if (this.isYouthHome) {
        return this.youthHomeIcon
      } else {
        const menu = this.getActivityMenu()
        if (menu) {
          return menu.icon
        } else {
          return 'fas fa-chess-knight'
        }
      }
    },
    isEvent() {
      return this.activity && this.activity.isEvent()
    },
    isYouthHome() {
      return +this.youthHome !== 0
    },
    baseColumns() {
      const columns = [
        {
          name: 'lastName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'families-detail',
                params: { entityId: item.entityId, },
                query: { tab: this.youthHome ? 'youth' : 'activities', },
              }
            ).href
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.individual)
          },
          linkUrl: item => {
            return router.resolve(
              {
                name: 'families-detail',
                params: { entityId: item.entityId, },
                query: { individual: item.individualId, },
              }
            ).href
          },
        },
        { name: 'email', label: 'Email', displayAs: 'email', },
        { name: 'phone', label: 'Téléphone', displayAs: 'phone', }
      ]
      if (this.hasDeadMembers) {
        columns.push({ name: 'info', label: '', })
      }
      if (this.displayMode === 'address') {
        columns.push({ name: 'address', label: 'Adresse', })
        columns.push({ name: 'zipCode', label: 'Code postal', })
        columns.push({ name: 'city', label: 'Ville', })
      } else if (this.displayMode === 'city') {
        columns.push({ name: 'city', label: 'Ville', })
      } else if (this.displayMode === 'comments') {
        columns.push({ name: 'comments', label: 'Commentaires', })
      }
      return columns
    },
    columns() {
      let columns = [].concat(this.baseColumns)
      if (this.hasTryingMembers) {
        columns.push(
          {
            name: 'trying',
            label: 'À l\'essai',
            alignCenter: true,
          }
        )
      }
      if (this.activity && this.activity.isEvent()) {
        const seats = distinct(
          this.activityInscriptions.reduce(
            (array, ins) => {
              return array.concat(ins.seats)
            },
            []
          ),
          'index'
        ).sort(compareNumbers)
        columns = columns.concat(
          seats.map(
            elt => {
              return { name: 'seat' + elt.index, label: elt.name, alignCenter: true, }
            }
          )
        )
      }
      for (const field of this.fields) {
        columns.push(
          {
            name: 'extraField' + field.id,
            label: field.text,
          }
        )
      }
      return columns
    },
    hasTryingMembers() {
      return this.inscriptions.filter(ins => ins.trying).length > 0
    },
    hasDeadMembers() {
      return this.inscriptions.filter(ins => ins.individual.deathDate).length > 0
    },
    managerColumns() {
      return [].concat(this.baseColumns)
    },
    waitingColumns() {
      let columns = [].concat(this.baseColumns)
      columns.push(
        {
          name: 'createdOn',
          label: 'Date Inscription',
          dateFormat: 'DD/MM/YYYY HH:mm',
        }
      )
      return columns
    },
    hasLeftColumns() {
      let columns = [].concat(this.baseColumns)
      columns.push(
        {
          name: 'hasLeftOn',
          label: 'Date de départ',
          dateFormat: 'DD/MM/YYYY',
        }
      )
      return columns
    },
    inscriptions() {
      return this.activityInscriptions.filter(
        elt => { return !elt.cancelled && !elt.waiting && !elt.hasLeftOn }
      ).map(
        elt => this.makeItem(elt)
      )
    },
    waitingInscriptions() {
      return this.activityInscriptions.filter(
        elt => { return !elt.cancelled && elt.waiting }
      ).map(
        elt => this.makeItem(elt)
      ).sort(
        (elt1, elt2) => compareDates(elt1.createdOn, elt2.createdOn)
      )
    },
    managerItems() {
      return this.activity.managedBy.map(
        elt => this.makeManagerItem(elt)
      )
    },
    cancelledInscriptions() {
      return this.activityInscriptions.filter(
        elt => { return (elt.refusedOn === null) && elt.cancelled }
      ).map(
        elt => this.makeItem(elt)
      )
    },
    refusedInscriptions() {
      return this.activityInscriptions.filter(
        elt => { return elt.refusedOn !== null }
      ).map(
        elt => this.makeItem(elt)
      )
    },
    hasLeftInscriptions() {
      return this.activityInscriptions.filter(
        elt => { return elt.hasLeftOn !== null }
      ).map(
        elt => this.makeItem(elt)
      )
    },
    canView() {
      if (this.youthHome) {
        return this.hasPerm('youth.view_youthhomeactivity')
      } else {
        return this.hasPerm('activities.view_coreactivity')
      }
    },
    canChange() {
      if (this.youthHome) {
        return this.hasPerm('youth.change_youthhomeactivity')
      } else {
        return this.hasPerm('activities.change_coreactivity')
      }
    },
    allIndLinkActivityInscriptions() {
      let inscriptions = this.activityInscriptions.filter(ins => !ins.cancelled)
      return inscriptions.sort(
        (ins1, ins2) => compareDates(ins1.createdOn, ins2.createdOn) || compareNumbers(ins1.id, ins2.id)
      )
    },
    indLinkActivityInscriptions() {
      const start = (+this.indLinkStartIndex) - 1
      const end = start + (+this.indLinkCount)
      return this.allIndLinkActivityInscriptions.slice(start, end)
    },
    showFilter() {
      return (
        this.waitingInscriptions.length ||
        this.cancelledInscriptions.length ||
        this.refusedInscriptions.length ||
        this.managerItems.length ||
        this.hasLeftInscriptions.length
      )
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      try {
        let url = '/api/activities/activities/' + this.activityId + '/'
        if (this.youthHome) {
          url = '/api/youth/activities/' + this.activityId + '/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activity = makeActivity(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadInscriptions() {
      this.startLoading(this.loadingName)
      try {
        let url = '/api/activities/activities/' + this.activityId + '/inscriptions/'
        if (this.youthHome) {
          url = '/api/youth/activities/' + this.activityId + '/inscriptions/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activityInscriptions = resp.data.inscriptions.map(elt => { return makeActivityInscription(elt) })
        this.fields = resp.data.fields.map(elt => makeActivityListField(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    viewPayments() {
      router.push(
        { name: 'activities-payments', params: { activityId: '' + this.activity.id, youthHome: this.youthHome, }, }
      )
    },
    viewSeances() {
      router.push(
        { name: 'activities-seances', params: { activityId: '' + this.activity.id, youthHome: this.youthHome, }, }
      )
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'inscriptions-' + slugify(this.activity.name)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    duplicateMe() {
      this.$bvModal.show(this.cloneModalId)
    },
    onShowGenerateIndividualLinks() {
      this.$bvModal.show('bv-modal-generate-individual-links')
    },
    async onGenerateIndividualLinks() {
      const docUrl = '/documents/activity-individual-links-document/<key>/pdf/'
      const start = (+this.indLinkStartIndex) - 1
      const end = start + (+this.indLinkCount)
      const docSlug = '' + this.activity.id + '-' + start + '-' + end
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onDownloadIndividualLinks() {
      try {
        const items = this.activityInscriptions.map(
          ins => {
            return {
              individual: ins.individual,
              entity: ins.entity,
            }
          }
        )
        let resp = await loadIndividualLinkDocuments(this.activity.name, items)
        this.showDocInfo = true
        this.docsSavedOn = resp.docsSavedOn
        this.missingDocIds = resp.missingDocIds
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    getExtraLinks() {
      const links = []
      if (!this.editMode) {
        if (!this.youthHome && this.hasPerm('activities.add_coreactivity')) {
          links.push(
            {
              id: 6,
              label: 'Dupliquer',
              callback: this.duplicateMe,
              icon: 'fas fa-clone',
              cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
            }
          )
        }
        if (this.canView) {
          links.push(
            {
              id: 5,
              label: 'Pdf',
              callback: this.printMe,
              icon: 'fa fa-file-pdf',
              cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
            }
          )
          links.push(
            {
              id: 4,
              label: 'Excel',
              callback: this.excelMe,
              icon: 'fa fa-file-excel',
              cssClass: 'btn-secondary',
            }
          )
          links.push(
            {
              id: 7,
              label: 'Télécharger les fiches liaisons',
              callback: this.onDownloadIndividualLinks,
              icon: 'fa fa-download',
              cssClass: 'btn-secondary',
            }
          )
          links.push(
            {
              id: 8,
              label: 'Générer les fiches liaisons',
              callback: this.onShowGenerateIndividualLinks,
              icon: 'fa fa-file-pdf',
              cssClass: 'btn-secondary',
            }
          )
        }
      }
      return links
    },
    getLinks() {
      const links = []
      if (!this.editMode) {
        if (this.hasPerm('activities.view_coreactivityinscription')) {
          links.push(
            {
              id: 3,
              label: 'Feuille de présences',
              callback: this.viewSeances,
              icon: 'fa fa-list-alt',
              cssClass: 'btn-secondary',
            }
          )
        }
        if (this.hasPerm('payments.view_payment')) {
          links.push(
            {
              id: 2,
              label: 'Paiements',
              callback: this.viewPayments,
              icon: 'fa fa-euro',
              cssClass: 'btn-secondary',
            }
          )
        }
        if (this.canChange) {
          links.push(
            {
              id: 1,
              label: 'Modifier',
              callback: this.editActivity,
              icon: 'fa fa-edit',
              cssClass: 'btn-secondary',
            }
          )
        }
        if (
          this.hasPerm('activities.add_coreactivityinscription') &&
          this.activity && !this.activity.disabled
        ) {
          links.push(
            {
              id: 4,
              label: 'Ajouter un membre',
              callback: this.addInscription,
              icon: 'fa fa-plus',
              cssClass: 'btn-primary',
            }
          )
        }
      }
      return links
    },
    countInscriptions(inscriptions) {
      return inscriptions.reduce(
        (acc, ins) => {
          return acc + ins.seats
        },
        0
      )
    },
    makeItem(elt) {
      let trying = ''
      if (elt.trying) {
        if (elt.tryingDate) {
          trying = dateToString(elt.tryingDate)
        } else {
          trying = 'Oui'
        }
      }
      let info = ''
      if (elt.individual.deathDate) {
        info = 'décès le ' + dateToString(elt.individual.deathDate)
      }
      const item = {
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        email: elt.individual.email,
        phone: elt.individual.cellPhone || elt.individual.proPhone,
        trying: trying,
        entityId: elt.entity.id,
        individualId: elt.individual.id,
        address: elt.entity.fullAddress(),
        city: elt.entity.city.name,
        zipCode: elt.entity.zipCode,
        individual: elt.individual,
        entity: elt.entity,
        createdOn: elt.createdOn,
        comments: elt.comments,
        info: info,
        hasLeftOn: elt.hasLeftOn,
      }
      for (const field of this.fields) {
        item['extraField' + field.id] = elt.getFieldText(field)
      }
      let totalSeats = 0
      for (const seat of elt.seats) {
        item['seat' + seat.index] = seat.seats
        totalSeats += seat.seats
      }
      item.seats = totalSeats
      return item
    },
    makeManagerItem(elt) {
      return {
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        email: elt.individual.email,
        phone: elt.individual.cellPhone || elt.individual.proPhone,
        individualId: elt.individual.id,
        city: elt.entity.city.name,
        zipCode: elt.entity.zipCode,
        individual: elt.individual,
        entity: elt.entity,
      }
    },
    editActivity() {
      this.editMode = true
    },
    onActivityUpdated(event) {
      this.activity = event.activity
      this.editMode = false
    },
    onEditCancelled(event) {
      this.loadData()
      this.editMode = false
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const activitySlug = slugify(this.activity.name)
      const docSlug = 'inscriptions-' + activitySlug
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onActivityCloned(newActivity) {
      router.push(
        { name: 'activities-detail', params: { activityId: '' + newActivity.id, }, }
      )
    },
    getLinkToActivity(activity) {
      if (this.isYouthHome) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
    addInscription() {
      this.$bvModal.show('add-activity-inscription-modal')
    },
    onNextIndLink() {
      const value = (+this.indLinkStartIndex) + (+this.indLinkCount)
      if (value < this.allIndLinkActivityInscriptions.length) {
        this.indLinkStartIndex = value
      } else {
        this.indLinkStartIndex = 1
      }
    },
  },
}
</script>

<style scoped lang="less">
  .activity-title {
    padding: 5px;
    background: #e0e0e0;
  }
  .disabled {
    background: #ffc0c0;
  }
  .table-small td {
    padding: 2px;
  }
   .elements-counter2 {
    font-weight: bold;
    padding: 2px;
    background: #ececec;
  }
</style>
